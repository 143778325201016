import { Loader } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { cn } from "#app/utils/misc";

export default function Image({src, alt,className, fallback = 'Sem imagem', aspectRatio = '16/9'}: {
    src?: string;
    alt: string;
    className?: string;
    aspectRatio?: '16/9' | '4/3' | 'square' | '9/16'
    fallback?: React.ReactElement | string
}) {
    const aspectRatioClassName = cn({
        'aspect-16-9': aspectRatio === '16/9',
        'aspect-4-3': aspectRatio === '4/3',
        'aspect-square': aspectRatio === 'square',
        'aspect-9-16': aspectRatio === '9/16'
    })
    return <Avatar className={cn("rounded-none h-auto w-full", className)}>
        {src ? <><AvatarImage
            className={cn("object-cover rounded-none", aspectRatioClassName)}
            src={src}
            alt={alt}
        />
    <AvatarFallback className={cn('rounded-none', aspectRatioClassName)}>
        <Loader className="animate-spin" />
    </AvatarFallback></> : <AvatarFallback className={cn('rounded-none text-center', aspectRatioClassName)}>
        {fallback}
    </AvatarFallback>}
</Avatar>
}